
import { Vue, Component, Watch } from 'vue-property-decorator';
import { universalRegExpChecker } from '@/utils/validationUtils';
import BaseInput from '@/components/baseComponents/BaseInput.vue';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import { sendEmailWithAuthLink } from '@/api/email';

@Component({
  components: { BaseInput, BaseButton },
})
export default class ForgotPasswordPage extends Vue {
  forgotPasswordStage = 'email';
  isPreloaderShow = false;
  errorEmail = false;
  isEmailValid = false;
  timeout: null | number = null;

  get email(): string {
    return this.$store.state.user.email;
  }
  set email(value: string) {
    this.$store.commit('user/setEmail', value);
  }
  get description(): string {
    return this.forgotPasswordStage === 'email'
      ? 'We will send you an email with a link  to reset your password.'
      : "We have sent a link to reset your password to your email address. If you didn't receive the email, please check your “spam” folder.";
  }
  get buttonText(): string {
    return this.forgotPasswordStage === 'email' ? 'Reset password' : 'Go home';
  }

  checkField(): void {
    this.errorEmail = !universalRegExpChecker('email', this.email);
  }
  onMountAndTypingValidation(fieldName: string): void {
    if (fieldName === 'email') this.isEmailValid = universalRegExpChecker('email', this.email);
  }
  async nextButtonAction(): Promise<void> {
    if (this.forgotPasswordStage === 'email') {
      try {
        this.isPreloaderShow = true;
        await sendEmailWithAuthLink(this.email, 'reset');
        this.forgotPasswordStage = 'goHome';
      } catch (error: any) {
        this.popupError(error.response.data.message);
      } finally {
        this.isPreloaderShow = false;
      }
    } else if (this.forgotPasswordStage === 'goHome') {
      this.$router.push({ name: 'HomePage' });
    }
  }
  popupError(errorText: string): void {
    this.$notify({
      group: 'main-notification',
      type: 'error',
      text: errorText,
    });
  }

  mounted(): void {
    if (this.email) {
      this.onMountAndTypingValidation('email');
    }
  }

  @Watch('email')
  emailWatcher(): void {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.onMountAndTypingValidation('email');
    }, 500);
  }
}
